import { useCallback, useEffect } from 'react';
import { CaseDTO, SampleDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetSamplesByCaseId = (caseId?: CaseDTO['id']) => {
  const { dispatch, result } = useApiWrapper<Array<SampleDTO>, 'samples'>(
    'GET:/api/Sample/GetSamplesByCaseId',
    'samples'
  );

  const dispatchWrapper = useCallback(() => {
    caseId && dispatch({ params: { caseId } });
  }, [caseId, dispatch]);

  useEffect(() => {
    dispatchWrapper();
  }, [dispatchWrapper]);

  return { ...result, refetch: dispatchWrapper };
};
