import { useEffect } from 'react';
import { CaseDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetAllCasesForUser = () => {
  const { dispatch, result } = useApiWrapper<Array<CaseDTO>, 'cases'>(
    'GET:/api/Case/GetAllCasesForUser',
    'cases'
  );

  useEffect(() => {
    result.status === 'idle' && dispatch();
  }, [dispatch, result.status]);

  return result;
};
