import { useEffect } from 'react';
import { CreateSampleCollectionDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

// Note this endpoint will create a new Sample
// collection if there isn't an active one already
export const useGetActiveSampleCollection = (
  caseId: CreateSampleCollectionDTO['caseId'],
  sampleIds: CreateSampleCollectionDTO['sampleIds']
) => {
  const { dispatch, result } = useApiWrapper<string, 'sampleCollectionId'>(
    'POST:/api/SampleCollection/GetActiveOrCreateSampleCollection',
    'sampleCollectionId'
  );

  useEffect(() => {
    dispatch({ data: { caseId, sampleIds } });
  }, [caseId, dispatch, sampleIds]);

  return result;
};
