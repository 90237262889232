import { useCallback } from 'react';
import { SampleCollectionDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useSendSampleCollection = () => {
  const { dispatch, result } = useApiWrapper<string, 'statusMsg'>(
    'POST:/api/SampleCollection/SendSampleCollection',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (sampleCollectionId: SampleCollectionDTO['id']) => {
      dispatch({ params: { sampleCollectionId } });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, result };
};
