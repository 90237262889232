import { useCallback } from 'react';
import { CaseDetailDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetCaseById = () => {
  const { dispatch, result, reset } = useApiWrapper<CaseDetailDTO, 'data'>(
    'GET:/api/Case/GetCase',
    'data'
  );

  const dispatchWrapper = useCallback(
    (id: CaseDetailDTO['id']) => {
      dispatch({ params: { id } });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, reset, result };
};
