import { useCallback, useEffect } from 'react';
import { SampleDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useGetSampleById = (sampleId?: SampleDTO['id']) => {
  const { dispatch, result } = useApiWrapper<SampleDTO, 'sample'>(
    'GET:/api/Sample/GetSampleById',
    'sample'
  );

  const dispatchWrapper = useCallback(
    (id: SampleDTO['id']) => {
      dispatch({ params: { id } });
    },
    [dispatch]
  );

  useEffect(() => {
    sampleId && dispatchWrapper(sampleId);
  }, [dispatchWrapper, sampleId]);

  return { dispatch: dispatchWrapper, ...result };
};
