import { useCallback } from 'react';
import { SampleDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

export const useDeleteSample = () => {
  const { dispatch, result } = useApiWrapper<string, 'statusMsg'>(
    'DELETE:/api/Sample/DeleteSample',
    'statusMsg'
  );

  const dispatchWrapper = useCallback(
    (id: SampleDTO['id']) => {
      dispatch({ params: { id } });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, result };
};
